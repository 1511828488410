<template>
  <div class="line inline-block">
    <color-text-btn size="mini" type="primary" @click="showDialog"> 详情 </color-text-btn>
    <EForm ref="form" :dialogVisible.sync="dialogVisible" :sup_this="sup_this" :data="data" :type="type" title="详情" />
  </div>
</template>

<script>
import EForm from './module/form'
import { commonEheaderMixin } from '@/mixins'

export default {
  mixins: [commonEheaderMixin],

  components: {
    EForm
  },

  props: {
    data: {
      type: Object,
      required: true
    },

    type: {
      type: String,
      default: 'default'
    }
  }
}
</script>