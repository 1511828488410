var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('avue-crud',{ref:"crud",staticClass:"flex-one-page",attrs:{"option":_vm.concatTableOption,"data":_vm.tableData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading,"resetMergeData":_vm.resetMergeData},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"headerBefore",fn:function(){return [_c('ProtoCustomFloorStatusGroup',{attrs:{"query":_vm.floorStatusGroupQuery}})]},proxy:true},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"info-wrapper"},[_c('DefaultImg',{attrs:{"disabled":true,"src":_vm.displayImage(row)}}),_c('div',{staticClass:"name-struct"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(row.chineseName)+" ")]),_c('span',{staticClass:"struct"},[_vm._v(" ("+_vm._s(_vm.structName(row) || '暂无')+") ")])])],1)]}},{key:"combinedChineseName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"info-wrapper"},[_c('DefaultImg',{attrs:{"disabled":true,"src":_vm.combinedDisplayImage(row)}}),_c('div',{staticClass:"name-struct"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(row.combinedChineseName)+" ")]),_c('span',{staticClass:"struct"},[_vm._v(" ("+_vm._s(_vm.combinedStructName(row) || '暂无')+") ")])])],1)]}},{key:"bottomBoardPreview",fn:function(ref){
var row = ref.row;
return [_c('DefaultImg',{attrs:{"carouselList":_vm.bottomBoardListPreview(row),"content":row.chineseName}})]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"time-wrapper"},[_c('span',[_vm._v("创建时间：")]),_c('span',[_vm._v(" "+_vm._s(_vm.bottomBoardListTimeList(row).createTime)+" ")])]),_c('div',{staticClass:"time-wrapper"},[_c('span',[_vm._v("更新时间：")]),_c('span',[_vm._v(" "+_vm._s(_vm.bottomBoardListTimeList(row).updateTime)+" ")])])]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [(_vm.childPermission.hasDetail)?_c('FloorDetail',{attrs:{"data":row,"type":_vm.option.type}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }