<template>
  <!-- 自定义底板列表列表 -->
  <div class="app-container">
    <avue-crud
      ref="crud"
      class="flex-one-page"
      :option="concatTableOption"
      :data="tableData"
      :page="tablePage"
      :tableLoading="tableLoading"
      :resetMergeData="resetMergeData"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #headerBefore>
        <ProtoCustomFloorStatusGroup :query="floorStatusGroupQuery" />
      </template>
      <!-- <template #create_timeSearchForm="{ form }">
        <SaleDateTime :time.sync="form['create_time']" @change="searchChange"></SaleDateTime>
      </template> -->

      <template #name="{ row }">
        <div class="info-wrapper">
          <DefaultImg :disabled="true" :src="displayImage(row)"></DefaultImg>
          <div class="name-struct">
            <span class="name">
              {{ row.chineseName }}
            </span>

            <span class="struct"> ({{ structName(row) || '暂无' }}) </span>
          </div>
        </div>
      </template>

      <template #combinedChineseName="{ row }">
        <div class="info-wrapper">
          <DefaultImg :disabled="true" :src="combinedDisplayImage(row)"></DefaultImg>
          <div class="name-struct">
            <span class="name">
              {{ row.combinedChineseName }}
            </span>

            <span class="struct"> ({{ combinedStructName(row) || '暂无' }}) </span>
          </div>
        </div>
      </template>

      <template #bottomBoardPreview="{ row }">
        <DefaultImg :carouselList="bottomBoardListPreview(row)" :content="row.chineseName"></DefaultImg>
      </template>

      <template #time="{ row }">
        <div class="time-wrapper">
          <span>创建时间：</span>
          <span>
            {{ bottomBoardListTimeList(row).createTime }}
          </span>
        </div>

        <div class="time-wrapper">
          <span>更新时间：</span>
          <span>
            {{ bottomBoardListTimeList(row).updateTime }}
          </span>
        </div>
      </template>

      <template #menu="{ row }">
        <FloorDetail v-if="childPermission.hasDetail" :data="row" :type="option.type" />
      </template>
    </avue-crud>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import SaleDateTime from '@/components/saleDateTime'
import DefaultImg from '@/views/components/defaultImg'

import FloorDetail from './module/floorDetail'

import ProtoCustomFloorStatusGroup from './module/protoCustomFloorStatusGroup'

import { singleCustomFloorList, combinationmCustomFloorList } from '@/api/product/customFloorApi'

import { SINGLE_PROTO_CUSTOM_FLOOR, COMBINATIONM_PROTO_CUSTOM_FLOOR } from '@/utils/constant'
import { tableOption, singleCustomFloorCols, combinationmFloorCols, list } from './const'
import { get, flatMapDeep } from 'lodash'
import { getBottomBoardListTimeList, checkPermission } from '@/utils'

const DEFAULT_STATUS = list[0].permission ? SINGLE_PROTO_CUSTOM_FLOOR : COMBINATIONM_PROTO_CUSTOM_FLOOR

export default {
  components: {
    SaleDateTime,
    DefaultImg,
    ProtoCustomFloorStatusGroup,

    FloorDetail
  },

  mixins: [avueCrud({ isInit: false })],

  data() {
    return {
      resetMergeData: {
        orderItems: [
          { asc: false, column: 'create_time' },
          { asc: true, column: 'id' }
        ]
      },
      floorStatusGroupQuery: {
        status: DEFAULT_STATUS
      }
    }
  },

  computed: {
    bottomBoardListPreview() {
      return (row) => {
        try {
          return row.bottomBoardList.map(({ previewImagePath }) => previewImagePath)
        } catch {
          return []
        }
      }
    },

    displayImage() {
      return (row) => {
        try {
          return get(row, 'styleList[0].styleDisplayImageList[0].displayImagePath')
        } catch {
          return ''
        }
      }
    },

    combinedDisplayImage() {
      return (row) => {
        try {
          return get(row, 'productPrototypeList[0].styleList[0].styleDisplayImageList[0].displayImagePath')
        } catch {
          return ''
        }
      }
    },

    structName() {
      return (row) => {
        try {
          return row.styleList.map(({ name }) => name).join('-')
        } catch {
          return ''
        }
      }
    },

    combinedStructName() {
      return (row) => {
        try {
          return flatMapDeep(row.productPrototypeList, (n) => {
            return [n.styleList]
          })
            .map(({ name }) => name)
            .join('-')
        } catch {
          return ''
        }
      }
    },

    /**
     * @description: 根据 bottomBoardList列表的创建时间和更新时间，获取最早的创建时间和最晚的更新时间
     * @param {Object}
     * @return {Object} {createTime: '最早的创建时间， updateTime： 最晚更新时间}
     */
    bottomBoardListTimeList() {
      return (row) => {
        return getBottomBoardListTimeList(row)
      }
    },

    option({ floorStatusGroupQuery }) {
      const { status } = floorStatusGroupQuery
      const list = {
        [SINGLE_PROTO_CUSTOM_FLOOR]: {
          column: singleCustomFloorCols,
          apiFunc: singleCustomFloorList,
          type: 'default',
          menu: true,
          childPermission: {
            hasDetail: checkPermission('externaladmin:user:protoCustomFloor:singleCustomFloor:detail')
          }
        },
        [COMBINATIONM_PROTO_CUSTOM_FLOOR]: {
          column: combinationmFloorCols,
          apiFunc: combinationmCustomFloorList,
          type: 'combinationmFloor',
          menu: false,
          childPermission: {
            // hasDetail: checkPermission('externaladmin:user:protoCustomFloor:combinationmFloor:detail')
            hasDetail: false
          }
        }
      }
      this.$nextTick(() => {
        this.searchChange()
      })
      return list[status]
    },

    childPermission({ option }) {
      return option.childPermission
    },

    concatTableOption({ option }) {
      const { column } = option
      return {
        ...tableOption,
        menu: option.menu,
        column
      }
    }
  },

  methods: {
    beforeInit() {
      this.tableData = []
    },

    getList(data) {
      return this.option.apiFunc(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  display: flex;
  align-items: center;
  .name-struct {
    margin-left: 10px;
  }
}
</style>
